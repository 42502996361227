import axios from 'axios'

axios.defaults.withCredentials = true

const api = axios.create({
  //baseURL: '/api/v1',
  baseURL: 'https://api.luckyspinwheel88.com/v1/api',
  withCredentials: true,
})
export default api
