export const makeError = (e) => {
  return {
    status: e.response.status,
    message: e.response.data.message,
  }
}

export const makeSuccess = (v) => {
  return {
    status: v.status,
    data: v.data,
  }
}
