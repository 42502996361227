import api from './axiosClient'
import { makeError, makeSuccess } from './axiosHelper'

export const login = {
  async getUserBySession() {
    try {
      const result = await api.get('/users')
      return makeSuccess(result)
    } catch (e) {
      return makeError(e)
    }
  },
  async login(username, password) {
    try {
      const result = await api.post('/login', {
        username,
        password,
      })
      return makeSuccess(result)
    } catch (e) {
      return makeError(e)
    }
  },
  async logout() {
    try {
      const result = await api.post('/logout', {})
      return makeSuccess(result)
    } catch (e) {
      return makeError(e)
    }
  },
}
