import React, { Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { useState } from 'react'
import { NextUIProvider } from '@nextui-org/react'
import { login } from './api/login'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))

const MainPage = ({ user, setUser }) => {
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="*" name="Home" element={<DefaultLayout user={user} setUser={setUser} />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

const LoginPage = ({ setUser }) => {
  return <Login setUser={setUser} />
}

const App = () => {
  /*
  const [user, setUser] = useState({
    id: 1,
    userID: 'abc123',
    password: 'abc123',
    role: 'Manager',
    email: 'warronyiang15@gmail.com',
    Remarks: 'testing',
    Join_Date: '2023-08-29',
  })
  */
  const [user, setUser] = useState(null)

  React.useEffect(() => {
    const fetchUser = async () => {
      const result = await login.getUserBySession()
      if (result.status === 200) {
        setUser(result.data.user)
      } else {
        //setUser(null)
      }
    }
    fetchUser()
  }, [])

  return (
    <NextUIProvider>
      {user === null ? <LoginPage setUser={setUser} /> : <MainPage user={user} setUser={setUser} />}
    </NextUIProvider>
  )
}

export default App
